








































































































import api from "@/api";
import {Component, Ref, Vue} from "vue-property-decorator";
import {HelpAppliesDto, AttachmentHostType, AuditFlowScope} from "@/api/appService";
import ProvinceCityAreaView, {
  ProvinceCityAreaTownDto
} from "@/components/CustomForm/FormDetail/components/ProvinceCityAreaView.vue";
import ProvinceCityAreaContent from "@/components/CustomForm/FormDetail/components/ProvinceCityAreaContent.vue";
import {AuditBodyDto} from "@/utils/customDto";
import moment from "moment";
import AttachmentsView from "@/components/AttachmentsView/index.vue";

import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

@Component({
  name: "HelpAppliesDtoDetail",
  components: {
    AuditBlock,
    ProvinceCityAreaView,
    ProvinceCityAreaContent,
    AttachmentsView,
  },
  filters: {}
})
export default class ServiceObjectDetail extends Vue {

  dataId?: number;
  detail: HelpAppliesDto = {};
  loading = true;
  attachments: any = [];
  hostType = AttachmentHostType.HelpApplies;


  async created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      await this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.helpApplies
      .get({id: this.dataId})
      .then((res) => {
        this.loading = false;
        this.detail = {...res};


        this.foundationArea = {
          provinceId: res!.provinceId ?? 0,
          cityId: res!.cityId ?? 0,
          areaId: res!.areaId ?? 0
        };

      });
    console.log(this.detail);
  }

  getTime(item: any) {
    return moment(item).format("HH:mm");
  }

  // 下载附件
  downLoad(item: any) {
    window.location.href = `${this.baseURL}/api/services/app/Attachment/Download?id=${item.id}`;
  }

  get auditApi() {
    return {
      canAudit: api.helpApplies.canAudit,
      userAudit: api.helpApplies.audit
    } as AuditApi
  }


  // 返回
  private cancel() {
    this.$router.back();
  }
}
