













































import { Component, Prop, Vue } from "vue-property-decorator";
import { AreaDto, CityDto, ProvinceDto, TownDto } from "@/api/appService";
import api from "@/api/index";

export interface ProvinceCityAreaTownDto {
  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  townId?: number;
}

@Component({
  name: "ProvinceCityAreaView",
})
export default class ProvinceCityAreaView extends Vue {
  @Prop({ required: false, default: {} })
  hostData!: ProvinceCityAreaTownDto;

  provinceList?: ProvinceDto[] = [];
  cityList?: CityDto[] = [];
  areaList?: AreaDto[] = [];
  townList?: TownDto[] = [];

  value = "";

  created() {
    this.init();
  }

  init() {
    Promise.all([
      this.fetchProvinceList(),
      this.fetchCityList(),
      this.fetchAreaList(),
      this.fetchTownList(),
    ]).then(() => {
      console.log(
        this.provinceList,
        this.cityList,
        this.areaList,
        this.townList
      );
    });
  }

  fetchProvinceList() {
    return new Promise<ProvinceDto[]>((resolve, reject) => {
      if (this.provinceList && this.provinceList.length) {
        resolve(this.provinceList);
      } else {
        api.province.getAllList().then((res) => {
          this.provinceList = res ?? [];
          resolve(this.provinceList);
        });
      }
    });
  }

  fetchCityList() {
    return new Promise<CityDto[]>((resolve, reject) => {
      if (this.cityList && this.cityList.length) {
        resolve(this.cityList);
      } else if (!this.hostData?.provinceId) {
        resolve([]);
      } else {
        api.city
          .getAllList({ provinceId: this.hostData.provinceId })
          .then((res) => {
            this.cityList = res ?? [];
            resolve(this.cityList);
          });
      }
    });
  }

  fetchAreaList() {
    return new Promise<AreaDto[]>((resolve, reject) => {
      if (this.areaList && this.areaList.length) {
        resolve(this.areaList);
      } else if (!this.hostData?.cityId) {
        resolve([]);
      } else {
        api.area.getAllList({ cityId: this.hostData.cityId }).then((res) => {
          this.areaList = res ?? [];
          resolve(this.areaList);
        });
      }
    });
  }

  fetchTownList() {
    return new Promise<TownDto[]>((resolve, reject) => {
      if (this.townList && this.townList.length) {
        resolve(this.townList);
      } else if (!this.hostData?.areaId) {
        resolve([]);
      } else {
        api.town.getAllList({ areaId: this.hostData.areaId }).then((res) => {
          this.townList = res ?? [];
          resolve(this.townList);
        });
      }
    });
  }
}
